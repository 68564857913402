.aside-links {
  position: sticky;
  top: 0;
  display: none;
  background-color: #113d33;
  color: whitesmoke;
  height: 100vh;
  width: 100%;
  max-width: 315px;
}

.aside-p-links {
  margin: 16px;
  text-wrap: nowrap;
  cursor: pointer;
}

.nyc-logo-fgts-dash {
  width: 100%;
}

@media (min-width: 768px) {
  .aside-links {
    display: block;
  }
}
