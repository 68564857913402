.seller-list {
  display: flex;
}

.th-seller {
  text-align: left;
  padding: 12px 0;
}

.seller-td {
  font-size: 12px;
  text-wrap: nowrap;
  text-align: center;
  padding: 8px;
  height: 30px;
}
.seller-tr {
}

.active {
  color: green;
  font-weight: 800;
  font-size: 14px;
}

.disabled {
  color: rgb(247, 71, 71);
  font-weight: 800;
  font-size: 14px;
}

.seller-table {
  margin-top: 20px;
  border-spacing: 0;
  width: 100%;
  background-color: #83a9a1;
  padding: 12px;
  border-radius: 8px;
}

.seller-button {
  color: white;
  padding: 8px;
  border-radius: 8px;
  font-weight: 600;
}

.back-ground-active {
  background-color: rgb(212, 72, 67);
}

.back-ground-disabled {
  background-color: green;
}

@media (min-width: 768px) {
  .seller-list {
    display: grid;
    grid-template-columns: 350px 1fr;
    margin-bottom: 0;
  }
}
