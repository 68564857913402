.confirm-contract {
  display: flex;
  flex-direction: row;
}

.main-confirm-contract {
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  max-width: 800px;
}

.create-contract-button {
  margin: 12px 0;
}
