.list-commission-fgts {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 8px;
}

.sub-text {
  margin-bottom: 20px;
}

.table-list-commission {
  display: grid;
  grid-template-columns: 1fr 32%;
  padding: 8px;
  gap: 8px;
}

@media (min-width: 768px) {
  .table-list-commission {
    grid-template-columns: 300px 1fr;
    padding: 12px;
  }

  .sub-text {
    margin-top: 10px;
  }
}
