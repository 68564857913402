.manager-area-page {
  margin: 25px 12px 12px 12px;
}

.area-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin: 12px;
}

.button-link {
  height: 40px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 12px;
  border-radius: 8px;
  font-size: 12px;
  background-color: #f3f3f3;
  cursor: pointer;
}
