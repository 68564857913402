body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
}

:root {
  --nyc-green-100: #15f987;
  --nyc-green-200: #07bb02;
  --nyc-green-250: #07ba01;
  --nyc-green-400: #113d33;
  --nyc-purple: #8753f0;
  --nyc-black-200: #202020;
  --nyc-gray: #b4b4b4;
  --nyc-black-100: #000000;
  --nyc-pink: #ef4d8e;
  --nyc-yellow: #f5ba19;
  --nyc-red: #b20505;
  --nyc-dark-black-100: #181818;
  --nyc-dark-black-200: #2c2c2c;
  --nyc-blue-gray-other: #607d8b;
  --nyc-purple-family: #9c27b0;
  --nyc-green-health: #4caf50;
  --nyc-yellow-work: #ffeb3b;
  --nyc-background-highlight: #ffffff;
  --nyc-components-background: #fff;
  --nyc-page-content-background: #e4e4e4;
  --nyc-default-text-color: #000000;
  --nyc-text-negative: #fff;
}

.main-container {
  grid-column: 2/3;
  margin-top: 50px;
  margin: 1%;
  overflow: hidden;
  max-width: 800px;
  margin-bottom: 90px;
}

option {
  font-size: 18px;
}

@media (min-width: 768px) {
  .main-container {
    margin-bottom: 0px;
    margin: 20px;
    padding: 0 12px;
  }
}
