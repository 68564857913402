#default-button {
  height: 43px;
  width: 100%;
  border: none;
  border-radius: 8px;
  background-color: #113d33;
  color: white;
}

.loading-button {
  width: 24px;
  height: auto;
  animation: rotation 2s infinite linear;
}

#default-button:disabled {
  background-color: #436c62;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
