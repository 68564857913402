.proposal-status {
  display: flex;
  flex-direction: row;
}

.text-alert-canceled {
  background-color: rgb(245, 90, 90);
  color: white;
  padding: 8px;
  border-radius: 8px;
  font-weight: 600;
}

.text-alert-send_link {
  background-color: rgb(255, 225, 0);
  color: rgb(0, 0, 0);
  padding: 8px;
  border-radius: 8px;
  font-weight: 800;
}

.text-alert-contract_signed {
  background-color: #35cc1d;
  color: rgb(255, 255, 255);
  padding: 8px;
  border-radius: 8px;
  font-weight: 800;
}

.text-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30% 0 0 8%;
}

.text-alert-contract_paid {
  background-color: #2e7b22;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  font-weight: 800;
}

.text-alert-commission_paid {
  background-color: #1d2bcca1;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  font-weight: 800;
}

.actions-table-body {
}

.main-container-proposal {
  grid-column: 2/3;
  margin-top: 50px;
  margin: 1%;
  overflow: hidden;
}

.nav-container {
  padding: 12px;
}
