.details-contract,
.confirm-contract {
  display: grid;
  margin-bottom: 90px;
}

.client-address,
.client-bank-info,
.client-contract-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 12px;
  border-radius: 8px;
  padding: 12px;
}

.sub-header-text {
  margin-left: 12px;
}

.client-bank-info {
  background-color: #cedcd9;
}

.client-address,
.client-contract-info {
  background-color: #83a9a1;
}

@media (min-width: 768px) {
  .grid-col2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }

  .details-contract,
  .confirm-contract {
    margin-bottom: 0px;
  }

  .details-client-main {
    margin: 20px;
  }
  .client-address,
  .client-bank-info,
  .client-contract-info {
    gap: 12px;
  }
}
