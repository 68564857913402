.toolbar-default {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.links-container-toolbar {
  display: flex;
  justify-content: space-around;
  background-color: #113d33;
  width: 100%;
}

.link-toolbar {
  background-color: #113d33;
  border: none;
  padding: 10px;
  width: 100px;
  flex-direction: column;
  align-items: center;
}

.text-link {
  color: white;
  text-wrap: wrap;
  font-size: 10px;
}

.image-link-toolbar {
  width: 20px;
  height: 20px;
}

@media (min-width: 768px) {
  .toolbar-default {
    display: none;
  }
}
