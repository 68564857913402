.nav-default-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding: 0 4%;
}

.employee-title,
.employee-name {
  font-size: 12px;
}

.employee-position {
  font-size: 12px;
}

@media (min-width: 768px) {
  .employee-title,
  .employee-name,
  .employee-position {
    font-size: unset;
  }
}
