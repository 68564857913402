.search-contract {
  display: flex;
  margin-bottom: 90px;
}

.table-info {
  overflow-x: auto;
}

.main-table-body {
  background-color: #83a9a1;
  border-radius: 8px;
}

.header-table {
  background-color: gray;
}

.simulation-td {
  padding: 0 10px;
}

.menu-icon-button {
  width: 100px;

  cursor: pointer;
}

.main-container-search {
  grid-column: 2/3;
  margin-top: 50px;
  margin: 1%;
  overflow: hidden;
}

.th {
  font-size: 14px;
  font-weight: 600;
  padding: 12px;
  color: white;
  text-wrap: nowrap;
}

@media (min-width: 768px) {
  .main-table {
    margin-bottom: 0px;
  }

  .simulation-row {
    font-size: 12px;
  }

  .search-contract {
    margin-bottom: 0px;
  }
}
