.input-container {
  margin: 0 8px;
}

.padding-bottom {
  margin-bottom: 8px;
}

.main-container-inputs-seller {
  width: 100%;
  max-width: 800px;
}

.grid-cols2-seller {
  display: grid;
  width: 100%;
  gap: 8px;
}

.seller-register {
  width: 100vw;
}

@media (min-width: 768px) {
  .seller-register {
    display: flex;
  }
  .grid-cols2-seller {
    grid-template-columns: 1fr 1fr;
  }
}
