.default-select-label {
  margin: 8px 0;
  font-size: 12px;
}

.default-select-select {
  height: 43px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 1px 0px #d1cccc;
  padding-left: 16px;
  width: 100%;
  margin-top: 8px;
}

.default-select-option {
}

.undefined-value {
  color: rgb(149, 20, 20);
}

@media (min-width: 768px) {
  .default-select-label {
    margin-top: 12px;
    margin-bottom: 10px;
    font-size: 14px;
  }
}
