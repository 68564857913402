.collaborator-pre-registration {
  border-radius: 8px;
  display: grid;
  justify-content: center;
  margin-top: 50px;
  margin: 12px;
}

.link-to-login {
  display: flex;
  justify-content: flex-end;
}

.collaborator-header {
  padding: 20px 0;
  font-size: 30px;
}

.sub-texts {
  padding: 20px 0;
}

.grid-col2 {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.pre-register {
  display: grid;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  border-radius: 8px;
  padding: 20px 0;
}

.register-store,
.personal-information-collaborator,
.bank-info,
.collaborator-address {
  background-color: #f6fafd;
  border-radius: 8px;
  padding: 20px;
  display: grid;
  gap: 12px;
  margin-bottom: 20px;
}

.button-register-collaborator {
  margin-top: 12px;
  width: 50%;
}

@media (min-width: 768px) {
  .grid-col2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }
}
