.commission-card-data {
  display: flex;
  margin-bottom: 90px;
}

.commission-table {
  margin: 30px 0;
  padding: 12px;
  margin: 20px 0;
  background-color: #f3f3f3;
  border-radius: 8px;
}

.grid-col2-commission {
  display: grid;
  grid-template-columns: 49.5% 49.5%;
  gap: 8px;
  width: 100%;
}

.grid-col3 {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 8px;
  width: 100%;
  margin: 12px 0;
}

@media (min-width: 768px) {
  .commission-card-data {
    margin-bottom: 0px;
  }
}
