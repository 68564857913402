.sub-title-pre-details {
  padding: 8px;
}

.details-pre-register-button,
.details-pre-register-button-list {
  border: none;
  background-color: #113d33;
  color: white;
  padding: 8px;
  border-radius: 8px;
}

@media (min-width: 768px) {
  .details-pre-register-button-list {
    float: right;
    width: 30%;
  }
  .sub-title-pre-details {
    font-size: 20px;
  }
}
