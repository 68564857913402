.loading-icon-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30%;
}

.loading-component {
  margin: 0 auto;
  width: 10%;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
