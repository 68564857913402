.details-client,
.details-contract {
  display: flex;
}

.link-button {
  display: flex;
  justify-content: flex-end;
  width: 95%;
  margin-bottom: 12px;
  padding: 12px;
}

.details-client-main {
  margin-top: 20px;
}

.download-ccb-button {
  max-width: 200px;
}

.header-page {
  margin: 0 0 20px 12px;
}

.sub-header-text {
  margin-bottom: 12px;
}

.sales-info,
.client-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #83a9a1;
  border-radius: 8px;
  padding: 12px;
}

.title-info {
  padding: 12px;
  grid-column: 1/3;
}

.client-info {
  background-color: #cedcd9;
  margin-top: 12px;
}

.detail-text {
  font-size: 12px;
}

.sub-text {
  color: rgb(101, 91, 91);
  padding-bottom: 8px;
}

/* .text-link {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
} */

@media (min-width: 768px) {
  .grid-col2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }

  .details-client-main {
    margin: 20px;
  }

  .detail-text {
    font-size: 16px;
  }

  .detail {
    padding: 12px;
  }
}
