.input-label-container {
  display: flex;
  flex-direction: column;
}

.input-label {
  margin: 8px 0;
  font-size: 12px;
}

.default-input {
  height: 43px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 3px 0px #d1cccc;
  padding-left: 12px;
}

#default-input::placeholder {
  opacity: 0.5;
}

.warning {
  border: 1px solid red;
  color: red;
}

@media (min-width: 768px) {
  .input-label {
    margin-bottom: 12px;
    font-size: 14px;
  }

  .default-input {
    padding-left: 16px;
  }
}
