.add-client-page {
  display: flex;
  position: relative;
  margin-bottom: 90px;
}

.add-client-form-main {
  background-color: #f6fafd;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
}

.text-header {
  margin-left: 12px;
  margin-top: 12px;
}

.default-form {
  padding: 16px;
  display: grid;
  gap: 12px;
}

.select-gender,
.select-bank-name,
.select-account-type {
  height: 43px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 1px 0px #d1cccc;
  padding-left: 16px;
  width: 100%;
}

.select-gender,
.select-bank-name,
.select-account-type {
  margin-top: 12px;
}

.address-information {
}

hr {
  margin: 0 16px;
}

.address-number {
  display: grid;
  grid-template-columns: 1fr 30%;
  gap: 12px;
}

@media (min-width: 768px) {
  .dashboard-page {
    display: grid;
    grid-template-columns: 300px 1fr;
  }

  .add-client-page {
    margin-bottom: 0px;
  }

  .nyc-logo-fgts-dash {
    width: 100%;
  }
  .add-client-form-main {
    margin: 20px;
    padding: 20px;
  }
}
