.withdraw-FGTS {
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
}

.simulation-row {
  background-color: #83a9a1;
  height: 43px;
  text-align: center;
  font-size: 12px;
  text-wrap: nowrap;
  padding: 0 5px;
}

.simulation-td {
  padding: 0 10px;
}

.mobile-menu {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 15px;
}

.main-menu {
  background-color: red;
}

.menu-icon {
  width: 35px;
  padding: 12px;
  position: absolute;
  top: -12px;
  left: 0;
}

.dialog-menu {
  position: absolute;
  top: 0;
  left: 0;
}

.main-table {
  overflow-x: auto;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  overflow-x: auto;
}

.main-table-body {
  background-color: #83a9a1;
  border-radius: 8px;
}

.header-table {
  background-color: gray;
}

.sellers-list-info {
  display: flex;

  gap: 20px;
}

.seller-list-label {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.option-seller {
  margin-top: 12px;
  background-color: red;
}

.seller-select {
  height: 43px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 1px 0px #d1cccc;
  padding-left: 16px;
  margin-top: 12px;
}

.phone-seller-select {
}

.th {
  font-size: 14px;
  font-weight: 600;
  padding: 12px;
  color: white;
  text-wrap: nowrap;
}

.search-container {
}

.input-search {
  box-shadow: 1px 1px 8px 0px #d1cccc;
  width: 40%;
  max-width: 400px;
  height: 43px;
  border-radius: 8px 0 0 8px;
  border: none;
  padding-left: 16px;
  margin-bottom: 20px;
}

.button-search {
  height: 43px;
  box-shadow: -1px 1px 8px 0px #d1cccc;
  border-radius: 0 8px 8px 0;
  border: none;
  background-color: #113d33;
  color: white;
  width: 90px;
}

.text-container {
  background-color: #113d33;
  height: 50px;
  border-radius: 8px 8px 0 0;
  padding: 12px;
  color: white;
}

.information-text {
}

.warning-text {
  background-color: red;
}

.attention-text {
}

.personal-information {
  margin: 20px 0;
  background-color: #f6fafd;
  border-radius: 8px;
  max-width: 800px;
}

.inputs-container {
  display: grid;
  gap: 12px;
  padding: 12px;
}

.new-value-text {
  margin-bottom: 12px;
}
.new-value-input-button {
  display: flex;
  align-items: flex-end;
}

.new-value-button {
  height: 45px;
  box-shadow: -1px 1px 8px 0px #d1cccc;
  border-radius: 0 8px 8px 0;
  border: none;
  background-color: #113d33;
  color: white;
  width: 90px;
}

.new-value-input {
  border-radius: 8px 0 0 8px;
  height: 43px;
}

.table-info {
  overflow-x: auto;
}

.main-credit-condition {
}

.condition-table {
  margin-top: 20px;
  border-spacing: 0;
  width: 100%;
  background-color: #f6fafd;
  padding: 12px;
  border-radius: 8px;
}

.header-table-condition {
}

.title-table-category {
  text-wrap: nowrap;
}
.th-condition {
  font-size: 12px;
  text-align: center;
  padding: 12px 0;
  text-wrap: nowrap;
}

.menu-icon-button {
  border: none;
  width: 80px;
  height: 28px;
  border-radius: 8px;
  background-color: #f6fafd;
  cursor: pointer;
}

.td-condition {
  padding: 12px 0;
  text-wrap: nowrap;
  font-size: 12px;
  text-align: center;
}

.input-condition {
  margin: 16px 0;
}

.label-condition {
}

.condition-payment-plan {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background-color: #f6fafd;
  padding: 16px;
  border-radius: 8px;
}

.condition-payment-title {
  padding: 12px 0;
  font-size: 18px;
}

.plans-dates,
.details-info {
  display: flex;
  justify-content: space-between;
}

.plans-dates-p,
.details-info-p {
  margin: 8px 0;
}

.plain-details {
}

.total-parcel-text {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.center {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 8px 0;
}

.data-info-details {
  display: flex;
  flex-direction: column;
}

.total-parcel-text-principal {
  font-size: 22px;
}

.total-parcel-text-value {
  font-size: 28px;
}

.logout {
  position: absolute;
  display: flex;
  bottom: 20px;
}

.tables-show {
  margin-top: 20px;
  overflow: auto;
}

@media (min-width: 768px) {
  .withdraw-FGTS {
    display: grid;
    grid-template-columns: 350px 1fr;
    margin-bottom: 0;
  }

  .simulation-row {
    font-size: 12px;
  }

  .main-table {
    margin-bottom: 0px;
  }

  .nyc-logo-fgts-dash {
    width: 100%;
  }
}
