.login-page {
}

.link-to-register {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: rgb(9, 9, 190);
}

.nyc-logo-fgts {
  width: calc(70vw - 40px);
  margin: 20px;
  max-width: 400px;
}

.form-login {
  display: grid;
  justify-content: center;
  height: 70%;
  gap: 14px;
  margin: 20px;
  text-align: center;
}

.title-form {
  opacity: 0.7;
}
